import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import PedidoItem from './pedidoItem/pedidoItem';

import { setModoTela, initForm, salvar, getListaItem, salvarItem, imprimir, excluir } from './pedidoActions';
import consts from '../consts';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import Aguardando from '../common/template/aguardando';

class PedidoLojaForm extends Component {

	state ={
		posicaoImagens: [],
		pedidoItemAlteracoes: []
	}

    componentWillMount() {
		this.props.getListaItem(this.props.formularioValues.id || 0);
    }

    render() {

		let readOnly = this.props.modoExcluir || this.props.formularioValues.datahora_confirmacao || this.props.formularioValues.datahora_cancelamento ? 'readOnly' : '';

        return (
			<>
				<Aguardando aguardando={this.props.aguardando} />

				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardHeader>
							{!this.props.formularioValues.lojaFixa && !this.props.formularioValues.id ? (
									<Row>
										<Field
											name='id_loja'
											component={Select}
											options={this.props.listaLoja.filter(item => 
												!(this.props.usuarioCarregado.lojas || []).length 
												|| (this.props.usuarioCarregado.lojas || []).filter(loja => loja.id == item.id).length
											)}
											label='Loja'
											cols='12 12 12 12'
											placeholder='Selecionea a loja' />
									</Row>
							) : null}

							{!this.props.formularioValues.id ? (
								<Row alignCenter>
									<Grid cols='6 6 4 3'>
										<Button
											text='Iniciar Pedido'
											submit
											type={'success'}
											icon={'fa fa-check'} />
									</Grid>
									<Grid cols='6 6 4 3'>
										<Button
											text='Voltar'
											type={'warning'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.setModoTela('lista')} />
									</Grid>
								</Row>
							) : (
								<>
									{readOnly &&
									<Row alignCenter style={{ marginBottom: 8 }}>
										{this.props.formularioValues.datahora_confirmacao &&
										<Grid cols='12 12 12 12'>
											<div style={{ textAlign: 'center', fontWeight: 'bold', width: '100%', padding: 8, fontSize: 14, color: '#ffffff', backgroundColor: '#00b350' }}>Pedido Confirmado{(this.props.formularioValues.id_usuario_impressao || []).includes(this.props.sessao.id) || (this.props.formularioValues.id_usuario_impressao_novo || []).map(imp => imp.id).includes(this.props.sessao.id) ? ' e Impresso' : ''}</div>
										</Grid>}
										{this.props.formularioValues.datahora_cancelamento &&
										<Grid cols='12 12 12 12'>
											<div style={{ textAlign: 'center', fontWeight: 'bold', width: '100%', padding: 8, fontSize: 14, color: '#ffffff', backgroundColor: '#db0000' }}>Pedido Cancelado</div>
										</Grid>}
									</Row>}
									<Row alignCenter>
										<div class="col-4 col-sm-4 col-md-4 col-lg-3">
											<Button
												text='Voltar'
												type={'warning'}
												icon={'fa fa-chevron-left'}
												event={() => this.props.setModoTela('lista')} />
										</div>
										<div class="col-4 col-sm-4 col-md-4 col-lg-3">
											<Button
												text='Excluir'
												type={'danger'}
												icon={'fa fa-chevron-left'}
												event={() => this.props.excluir({ ...this.props.formularioValues })} />
										</div>
										<div class="col-4 col-sm-4 col-md-4 col-lg-3">
											<Button
												text='Imprimir'
												type={'primary'}
												icon={'fa fa-chevron-left'}
												event={() => this.props.imprimir({ ...this.props.formularioValues, cnpj_loja: FormatUtils.formatarCNPJ(this.props.formularioValues.cnpj_loja) })} />
										</div>
									</Row>
									{!readOnly &&
									<Row alignCenter style={{ marginTop: 8 }}>
										<Grid cols='6 6 4 3'>
											<Button
												text='Confirmar Pedido'
												type={'success'}
												icon={'fa fa-chevron-left'}
												event={() => this.props.salvar({
													...this.props.formularioValues,
													datahora_confirmacao: DateFormat.getDataHoraAtual(),
													id_usuario_confirmacao: this.props.sessao.id
												})} />
										</Grid>
										<Grid cols='6 6 4 3'>
											<Button
												text='Cancelar Pedido'
												type={'danger'}
												icon={'fa fa-chevron-left'}
												event={() => this.props.salvar({
													...this.props.formularioValues,
													datahora_cancelamento: DateFormat.getDataHoraAtual(),
													id_usuario_cancelamento: this.props.sessao.id
												})} />
										</Grid>
									</Row>}
								</>
							)}
						</ContentCardHeader>

						{this.props.formularioValues.id ? (
							<ContentCardBody>
								<Row>
									<Field
										name='id_categoria'
										component={Select}
										options={this.props.listaCategoria}
										label='Categoria'
										cols='12 12 12 12'
										placeholder='Selecionea a categoria' />

									<Grid>
										<input className='form-control'
											placeholder='Pesquisar'
											type='text'
											value={this.state.pesquisar}
											onChange={e => {
												this.setState({ ...this.state, pesquisar: e.target.value });
											}} />
									</Grid>
								</Row>
							</ContentCardBody>
						) : null}
					</Form>					
				</ContentCard>				

				{this.props.formularioValues.id ? (
					this.listaItens(readOnly)
				) : null}
			</>
        )
    }

	listaItens(readOnly) {

		let listaProdutoModelo = this.props.listaProdutoModelo
			.filter(item =>
				!this.props.formularioValues.id_categoria || item.id_categoria == this.props.formularioValues.id_categoria
			).filter(item => 
				!readOnly || this.props.listaItem.filter(pedidoItem => pedidoItem.id_modelo == item.id_modelo)[0]
			).filter(item => 
				readOnly || !item.removido
			).filter(item => {
				if (this.state.pesquisar
					&& !(`${item.nome_categoria} ${item.nome_produto} ${item.nome_modelo}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
					return false;
				}
				return true;
			});

		return (
			<>
				{listaProdutoModelo.map(item => {

					let posImagem = (this.state.posicaoImagens || []).filter(posicaoImagem => posicaoImagem.id_modelo == item.id_modelo)[0];
					posImagem = posImagem ? posImagem.posicao : 0;

					let pedidoItem = this.props.listaItem.filter(pedidoItem => pedidoItem.id_modelo == item.id_modelo)[0];

					let pedidoItemAlteracao = this.state.pedidoItemAlteracoes.filter(pedidoItemAlteracao => pedidoItemAlteracao.id_modelo == item.id_modelo)[0];

					//Contabilizar quantidade de cada produto
					let qtdTotalProduto = 0;
					this.props.listaItem.filter(pedidoItem => pedidoItem.id_categoria == item.id_categoria && pedidoItem.id_produto == item.id_produto).forEach(contabilizaProduto => {
						qtdTotalProduto += parseInt(contabilizaProduto.quantidade || 0);
					});

					const possuiImagens = (item.imagens || []).length > 0;

					return (
						<ContentCard key={item.id_modelo}>
							<ContentCardHeader>
								<Row>
									<Grid cols='12 12 12 12'>
										<div style={{ fontSize: 12, fontWeight: 'bold' }}>{item.nome_categoria} - {item.nome_produto} - {item.nome_modelo}</div>
									</Grid>
								</Row>
							</ContentCardHeader>
							<ContentCardBody>
								<Row>
									<Grid cols='12 12 12 12'>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
											{possuiImagens &&
											<div style={{ backgroundColor: '#1a346c', padding: 1 }}>
												<img src={((item.imagens || [])[posImagem] || {}).imagem ? `${consts.URL}/${((item.imagens || [])[posImagem] || {}).imagem}` : null} style={{ width: 200, height: 200 }} />
												
												<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
													<button type='button' 
														style={{ margin: 0, marginRight: 1 }} 
														class='btn btn-block btn-dark btn-sm'
														onClick={() => {
															let listaPosicaoImagem = (this.state.posicaoImagens || []).filter(posicaoImagem => posicaoImagem.id_modelo != item.id_modelo);
															listaPosicaoImagem.push({
																id_modelo: item.id_modelo,
																posicao: (item.imagens || [])[posImagem - 1] ? posImagem - 1 : (item.imagens || []).length - 1
															});
															this.setState({
																...this.state,
																posicaoImagens: listaPosicaoImagem
															});
														}}>
														{'<<'}
													</button>
													<button type='button' 
														style={{ margin: 0, marginLeft: 1 }}
														class='btn btn-block btn-dark btn-sm'
														onClick={() => {
															let listaPosicaoImagem = (this.state.posicaoImagens || []).filter(posicaoImagem => posicaoImagem.id_modelo != item.id_modelo);
															listaPosicaoImagem.push({
																id_modelo: item.id_modelo,
																posicao: (item.imagens || [])[posImagem + 1] ? posImagem + 1 : 0
															});
															this.setState({
																...this.state,
																posicaoImagens: listaPosicaoImagem
															});
														}}>
														{'>>'}
													</button>
												</div>
											</div>}
											<div style={{ display: 'flex', flexDirection: possuiImagens ? 'column' : 'row' }}>

												<div 
													style={{ 
														backgroundColor: '#dbe7ff', 
														color: '#1a346c',
														padding: 4,
														fontSize: 12,
														fontWeight: 'bold',
														width: 84,
														textAlign: 'center',
														marginBottom: 2,
														borderRadius: 4
													}}>
													<p style={{ padding: 0, margin: 0 }}>{qtdTotalProduto}</p>
													<p style={{ padding: 0, margin: 0, fontSize: 10 }}>Selecionado(s)</p>
												</div>
												
												<div 
													style={{ 
														backgroundColor: '#1a346c', 
														color: '#ffffff',
														padding: 10,
														fontSize: 16,
														fontWeight: 'bold',
														width: possuiImagens ? 84 : 60,
														textAlign: 'center',
														marginBottom: 2,
														marginLeft: possuiImagens ? null : 4,
														borderRadius: 4
													}}>{pedidoItemAlteracao ? pedidoItemAlteracao.quantidade : (pedidoItem ? pedidoItem.quantidade : 0)}</div>
												
												<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
													<button type='button' 
														style={{ margin: 0, marginRight: 1, marginBottom: 2, fontWeight: 'bold', padding: 8, minWidth: 40, marginLeft: possuiImagens ? 0 : 4 }} 
														class='btn btn-block btn-dark btn-sm'
														disabled={readOnly}
														onClick={() => {
															if (!readOnly) {
																if (!pedidoItemAlteracao) {
																	if (pedidoItem) {
																		pedidoItemAlteracao = {
																			...pedidoItem
																		};
																	} else {
																		pedidoItemAlteracao = {
																			id_pedido: this.props.formularioValues.id,
																			id_produto: item.id_produto,
																			id_modelo: item.id_modelo,
																			quantidade: 0
																		};
																	}
																}
																if (pedidoItemAlteracao.quantidade > 0) {
																	pedidoItemAlteracao = {
																		...pedidoItemAlteracao,
																		quantidade: pedidoItemAlteracao.quantidade - 1
																	};
																}
																let pedidoItemAlteracoes = this.state.pedidoItemAlteracoes.filter(pedidoItemAlteracao => pedidoItemAlteracao.id_modelo != item.id_modelo);
																pedidoItemAlteracoes.push(pedidoItemAlteracao);

																this.setState({
																	...this.state,
																	pedidoItemAlteracoes: pedidoItemAlteracoes
																});
															}
														}}>
														{'-'}
													</button>
													<button type='button' 
														style={{ margin: 0, marginBottom: 2, marginLeft: 1, fontWeight: 'bold', padding: 8, minWidth: 40 }}
														class='btn btn-block btn-dark btn-sm'
														disabled={readOnly}
														onClick={() => {
															if (!readOnly) {
																if (!pedidoItemAlteracao) {
																	if (pedidoItem) {
																		pedidoItemAlteracao = {
																			...pedidoItem
																		};
																	} else {
																		pedidoItemAlteracao = {
																			id_pedido: this.props.formularioValues.id,
																			id_produto: item.id_produto,
																			id_modelo: item.id_modelo,
																			quantidade: 0
																		};
																	}
																}
																pedidoItemAlteracao = {
																	...pedidoItemAlteracao,
																	quantidade: pedidoItemAlteracao.quantidade + 1
																};
																
																let pedidoItemAlteracoes = this.state.pedidoItemAlteracoes.filter(pedidoItemAlteracao => pedidoItemAlteracao.id_modelo != item.id_modelo);
																pedidoItemAlteracoes.push(pedidoItemAlteracao);

																this.setState({
																	...this.state,
																	pedidoItemAlteracoes: pedidoItemAlteracoes
																});
															}
														}}>
														{'+'}
													</button>
												</div>

												<div style={{ marginTop: 4, height: 40 }}>
													{pedidoItemAlteracao &&
													<button type='button' 
														style={{ margin: 0, marginLeft: 1, fontWeight: 'bold', padding: 8 }}
														class='btn btn-block btn-outline-dark btn-sm'
														onClick={() => {
															this.props.salvarItem(pedidoItemAlteracao, 
																() => this.setState({
																	...this.state,
																	pedidoItemAlteracoes: this.state.pedidoItemAlteracoes.filter(pedidoItemAlteracao => pedidoItemAlteracao.id_modelo != item.id_modelo)
																})
															);
														}}>
														Confirmar
													</button>}
												</div>
											</div>
										</div>
									</Grid>
								</Row>
							</ContentCardBody>
						</ContentCard>
					);
				})}
			</>
		)
	}


}

PedidoLojaForm = reduxForm({form: 'pedidoForm', destroyOnUnmount: false})(PedidoLojaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('pedidoForm')(state),
	registro: state.pedido.registro,
	aguardando: state.pedido.aguardando,
	usuarioCarregado: state.auth.usuarioCarregado,
	listaLoja: state.pedido.listaLoja,
	listaCategoria: state.pedido.listaCategoria,
	listaProdutoModelo: state.pedido.listaProdutoModelo,
	listaItem: state.pedido.listaItem
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, getListaItem, salvarItem, imprimir, excluir }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PedidoLojaForm);
